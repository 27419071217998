.Description {
    margin-top: -3em;
    /* height: 92px; */
}

.Description h6 {
    font-family: "Lato", sans-serif;
    font-size: 10px;
    font-weight: 700;
    color: white;
}

.Description p {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    line-height: 18px;
}