.stepContainer {
  padding-left: 3em;
}

.heading {
  color: #1c1c1c !important;
  font-family: Roboto;
  font-size: 130px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.1em;
}

.secondHeading {
  color: #1c1c1c !important;
  font-family: Lato;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0.9em;
  margin-bottom: 0.8em;
}

.paraContent {
  color: #1c1c1c !important;
  font-size: 18px;
  font-weight: 400 !important;
  letter-spacing: 1.29px;
  line-height: 21px;
  font-weight: 700;
}

.logBtn {
  border: none;
  outline: none;
  background: #1ed95f;
  color: white;
  font-weight: 500;
  padding: 0.8em 1.8em;
  font-size: 1.2em;
  padding-left: 1em;
  border-radius: 35px;
}

.logBtn img {
  width: 28px;
  margin-right: 1.1em;
}

.stepImage {
  width: 457px;
  height: 278px;
}

.leftSide {
  position: relative;
}

.leftSide:after {
  content: "";
  width: 1px;
  height: 530px;
  background: #707070;
  position: absolute;
  right: -70px;
  top: 210px;
}

.rightContainer {
  padding-left: 155px;
}

.formSteps {
  position: fixed;
  right: 16px;
  bottom: 110px;
  z-index: 99;
}

.stepButton {
  background: transparent;
  border: none;
  color: #1c1c1c;
  font-size: 1.8em;
  font-weight: bold;
}

.categoryItem {
  width: 105px;
  height: 111px;
  border-radius: 5px;
  background-color: rgba(64, 64, 64, 0.26);
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgba(64, 64, 64, 0.26);
}

.categoryItem .circleButton {
  width: 6px;
  height: 7px;
  border: 1px solid #707070;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.categoryItem p {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  position: absolute;
  bottom: -3px;
  left: 10px;
}

.categoryItem img {
  width: 22px;
  height: 23px;
}

.roundedBtn {
  border: 1px solid gainsboro;
  background: transparent;
  border-radius: 5px;
  font-size: 14px;
  min-width: 80px;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.activeBtn {
  border-color: green;
}
