.container {
    min-width: 302px;
    height: 90px;
    border-radius: 10px;
    margin-right: 0.4em;
    padding: 0.9em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s ease-in;
    background-blend-mode: multiply;
    background-size: cover !important;
    background-position: 50% !important;
    position: relative;
    filter: grayscale(1);
    opacity: 1;
    cursor: pointer;
}

.activeItem {
    min-width: 413px;
    opacity: 1;
    filter: none;
}

.imgContainer {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
}

.imgContainer img:first-child {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.imgContainer img:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 25px;
}

.imgContainer .thumbGradient {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #1c1c1c;
    opacity: 0.5;
    border-radius: 10px;
    z-index: 99;
}

.title {
    width: 160px;
    height: 32px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 10px;
    white-space: pre-wrap;
}

.source {
    width: 170px;
    height: 14px;
    color: white;
    font-size: 11px;
    overflow: hidden;
}

.forward {
    /* Style for "Forward" */
    width: 31px;
    height: 20px;
    cursor: pointer;
}

.overlay {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    border-radius: 10px;
    background: rgb(119 119 119 / 90%);
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.overlay+div {
    z-index: 9999;
    position: relative;
    width: 100%;
    height: 100%;
}

.overlay+div+div {
    z-index: 9999;
    position: relative;
}

.responsiveIcon {
    display: none;
}

.responsiveTypeIcon {
    display: none;
}

.container:hover {
    opacity: 1;
    filter: grayscale(0);
}

.container:hover .overlay {
    background: inherit;
    background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0.58) 48%, rgba(0, 0, 0, 0.69) 54%, rgba(0, 0, 0, 0.84) 100%);
}

@media (max-width: 768px) {
    .actionIcons {
        display: none;
    }
    .responsiveIcon {
        width: 26px;
        height: 26px;
        left: -7px !important;
        bottom: 0px !important;
        transform: none !important;
        margin-top: 9px !important;
        cursor: pointer;
        top: unset !important;
        display: block !important;
        position: absolute;
    }
    .responsiveTypeIcon {
        display: flex;
        color: white !important;
        font-size: 10px;
        align-items: center;
        position: absolute;
        right: -6px;
        top: -8px;
        text-transform: capitalize;
    }
    .responsiveTypeIcon img {
        width: 11px !important;
        height: 10px !important;
        position: static !important;
        transform: none !important;
        margin-right: 5px;
    }
    .activeItem .actionIcons {
        display: block;
    }
    .container {
        filter: grayscale(0);
        opacity: 1;
    }
    .overlay {
        background: transparent;
        background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0.58) 48%, rgba(0, 0, 0, 0.69) 54%, rgba(0, 0, 0, 0.84) 100%);
    }
    .container .title,
    .container .source {
        display: none;
    }
    .expanded .title {
        margin-top: 4em;
    }
    .container {
        max-width: 80px;
        min-width: unset;
    }
    .activeItem {
        max-width: 180px;
        min-width: unset;
    }
    .activeItem .title,
    .activeItem .source {
        display: block;
        white-space: pre-wrap;
    }
    .title {
        width: 94%;
        height: 38px;
        margin-bottom: 3px;
    }
    .activeItem .source {
        width: 94%;
    }
    .container>div:last-child {
        display: none !important;
    }
    .expanded {
        height: 280px;
        min-width: 180px;
        align-items: flex-start;
    }
    .expanded .imgContainer {
        display: none;
    }
    .expanded .responsiveIcon {
        left: unset !important;
        right: 0;
        width: 51px;
        height: 51px;
    }
    .expanded .title,
    .expanded .source {
        display: block;
    }
    .expanded .source {
        color: #a9a7a7;
    }
}