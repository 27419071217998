.tabsContainer {
    margin-left: 11em;
    width: 67%;
    overflow: scroll;
    height: 100vh;
}

@media (max-width: 1300px) {
    .tabsContainer {
        width: 100%;
    }
}