.container {
    position: fixed;
    right: 26%;
    border-radius: 6px;
    padding: 1em;
    bottom: 0;
    width: 299px;
    height: 403px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #a8c0d4;
    z-index: 99999;
}

.container>p {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2em;
}

.container>div:first-child {
    margin-bottom: 1em;
}

.container>div>p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
    color: black;
}

.container>div>p+img {
    width: 30px;
    margin-left: auto;
    cursor: pointer;
    filter: invert(1);
}

.container>div>img {
    margin-top: 0;
}

.shareCard {
    background-color: #141414;
}

.shareCard p {
    color: white !important;
}


/* .shareCard>div>img {
    filter: invert(1);
} */

.shareCard>div>p+img {
    filter: none;
}