.ProjectLabel {
  margin-bottom: 2em;
  position: fixed;
  z-index: 9;
  top: 60px;
}

.ProjectLabel span {
  padding: 0.3em 0.8em;
  font-size: 1.125em;
  font-weight: 700;
  font-family: Roboto;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  color: #999999;
}

.ProjectLabel span.active {
  border: 1px solid #38ef7d;
  color: #38ef7d;
}

.ProjectCards {
  display: flex;
  align-items: center;
  margin-top: 8em;
  overflow: auto;
  justify-content: start;
  padding: 6px;
  padding-left: 10px;
  overscroll-behavior: contain;

  /* margin-bottom: 12em; */
  /* height: 100vh; */
}

.AllProject {
  margin-left: 15em;
  margin-right: 38em;
  width: 100%;
}

.statItem {
  width: 208px;
  height: 90px;
  border-radius: 5px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1.5em;
}

.statItem h4 {
  color: #1c1c1c;
  font-size: 29px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  margin-bottom: 0;
}

.statItem span {
  color: #1c1c1c;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.statItem.active {
  background-color: rgba(255, 255, 255, 0.43);
}

@media (max-width: 1300px) {
  .ProjectCards {
    padding-top: 6.5em;
  }
}
