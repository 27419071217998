.container {
    border-radius: 15px;
    margin: 0 15px;
    width: 87.5%;
    margin-bottom: 40px;
}

.templateItem {
    flex: 1;
    cursor: pointer;
    border: 1px solid #999;
    max-width: 220px;
    width: 220px;
    height: 170px;
    margin-right: 30px;
}