.container {
    padding: 1em;
}

.closeBtn {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.heading {
    color: #38ef7d;
    font-weight: 600;
    font-size: 1.2em;
}

.container>div:nth-child(1),
.container>div:nth-child(2) {
    position: relative;
    z-index: 9999999;
}