.PlayCard {
    border: 1px solid #484848;
    border-radius: 10px;
    padding: 1em 0.5em 0.5em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 290px;
    height: 180px;
    margin-right: 1em;
    color: white;
    position: relative;
    filter: grayscale(1);
    position: relative;
    background-size: cover !important;
}

.PlayCard:after {
    content: "";
    position: absolute;
    right: 0;
    left: top;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.95;
    border-radius: 9px;
    background-color: rgba(36, 35, 38, 0.93) !important;
}

.PlayCard>div {
    position: relative;
    z-index: 999;
}

.PlayCard img {
    filter: invert(1);
}

.PlayCard h5 {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    margin-bottom: 0;
    height: 49px;
    overflow: hidden;
}

.PlayCard h6 {
    font-size: 8px;
    font-family: Lato;
    font-weight: 500;
    margin-bottom: 0;
}

.PlayCard p {
    font-size: 11px;
    font-family: Lato;
    font-weight: 500;
    margin-bottom: 0;
}

.PlayCard .ActionButton {
    align-self: flex-end;
}

.PlayCard .ActionButton img {
    margin: 0 0.2em;
    cursor: pointer;
    filter: invert(1);
}

.contactName {
    font-size: 1.1em !important;
    font-weight: bold !important;
    height: 33px !important;
}

.contactCard p {
    font-size: 12px;
}

.cardContent {
    position: relative;
    margin: 0;
    width: 100%;
}

.backImage {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.imageGradient {
    position: absolute;
    width: 60px;
    height: 100%;
    right: 0;
    top: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.backImage img {
    opacity: 0.09;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    filter: grayscale(1);
}