.button {
  min-width: 316px;
  height: 56px;
  border-radius: 15px;
  background-color: #393939;
  border: none;
  outline: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
