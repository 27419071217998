.sharingHeading {
  font-size: 2.4em;
  margin: 0;
  font-weight: bold;
}

.headImage {
  width: 133px;
  height: 25px;
}

.sectHeading {
  font-size: 1.4em;
}

.actionContainer {
  border-top: 1px solid #747172;
  margin: 0 -5px;
}

.actionContainer div {
  text-align: center;
  cursor: pointer;
}

.actionContainer button {
  background: none;
  border: none;
}

.actionContainer div img {
  display: block;
  width: 35px;
  height: 35px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}

.loadIcon {
  font-size: 5em;
  margin-bottom: 0.3em;
}

.shareArticleImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.shareArticleTitle {
  max-height: 40px;
  overflow: hidden;
  font-size: 1.2em;
}

.shareEmail .ant-modal-content {
  width: 656px;
  height: 838px;
  border-radius: 15px;
}

.shareEmail .ant-modal-footer {
  display: none;
}

.crossIcon {
  color: #bcbcbc;
  font-size: 2.5em;
}

.emailGradient {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14) 0%,
    rgba(0, 0, 0, 0.97) 100%,
    rgba(0, 0, 0, 0.66) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.emailImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.emailContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  padding: 5em 2.5em;
  padding-bottom: 1em;
  font-family: Poppins;
}

.emailContent h4 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 3em;
}

.artContainer {
  position: relative;
  height: 190px;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.templateThumb {
  width: 97px;
  height: 154px;
  filter: grayscale();
}

.selectBtn {
  width: 94px;
  height: 40px;
  border-radius: 14px;
  background-color: #393939;
  color: #96969a;
  font-weight: 500;
  display: block;
  line-height: 1px;
  font-size: 1.1em;
}

.selectBtnActive {
  background-color: #046ee8;
  color: white;
}

.templateItem {
  margin-right: 4em;
  width: 97px;
}

.templateThumbspan {
  color: rgb(255, 255, 255);
  font-family: Quicksand;
  font-size: 14px;
  height: 55px;
  display: block;
}

.emailInput {
  width: 371px;
  height: 55px;
  border-radius: 14px;
  background-color: #393939;
  color: #b8c0c9;
  border: none;
  outline: none;
  padding: 1.3em 1.1em;
  font-size: 1.3em;
}

.embedLink {
  min-height: 400px !important;
  width: 1070px !important;
}

.embedLink .ant-modal-content {
  min-height: 400px !important;
}

.embedLink .ant-modal-body {
  min-height: 400px !important;
}
