html {
  height: 100%;
  overflow: auto;
  font-size: 1em;
  overflow-x: hidden;
}

html,
body,
#root,
#root > div,
#root > div > .body-wrapper {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-family: "Roboto", sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  overflow-y: overlay !important;
  color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}

.internet-error {
  height: 60px;
  background: #ff8100;
  margin-top: 0;
  font-size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}

.internet-error p {
  font-size: 25px;
  line-height: 60px;
  color: #fff;
  margin: 0;
  padding: 0.1em 1.6em;
}

.dropdown-menu {
  z-index: 99999;
}

.body-orange {
  background-color: #faad14 !important;
}

.body-orange .page-content {
  background-color: #faad14 !important;
}

.text-quicksand {
  font-family: Quicksand;
}

.session {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.page-content .ch-pad {
  padding: 100px;
}

.page-content .session {
  margin: 0 auto;
  max-width: 330px;
  width: 100%;
  text-align: center;
}

.page-content > .ch-pad > .session > h1 {
  margin-bottom: 0.5em;
  font-weight: 800;
  line-height: 1.125;
  color: #1c1c1c !important;
  margin-top: 1em;
}

.page-content > .ch-pad > .session > .form {
  margin: 2em auto 1em auto;
}

.page-content > .ch-pad > .session > .form > .field > .note {
  margin: 0.5em 0 0 0.2em;
  opacity: 0.75;
  font-size: 0.888888em;
  margin-top: 2em;
}

.page-content > .ch-pad > .session > .form > .field > .note > a {
  color: #000000;
}

.page-content > .ch-pad > .session > .form > .actions {
  margin-top: 2em;
}

.page-content > .ch-pad > .session > .login-strategy {
  margin-top: 1.7em;
  opacity: 0.75;
  font-size: 1.675em;
  text-align: center;
}

.page-content > .ch-pad > .session > .login-strategy > a {
  font-weight: 800;
  text-decoration: underline;
  color: #000000;
}

.page-content .session-form .google-btn,
.page-content .session-form .kep-login-facebook {
  display: block;
  background: #eaeaea;
  color: #1c1c1c;
  border-radius: 25px;
  padding: 0.4em 4em;
  font-size: 18px !important;
  margin-bottom: 1em;
  font-weight: bold;
  padding-left: 1em;
  text-transform: none;
  height: 50px;
}

.page-content .session-form .kep-login-facebook {
  border: none;
  color: white !important;
  background: #4a66a0;
  padding-right: 52px;
  line-height: 0;
  padding-bottom: 10px;
  padding-left: 26px;
}

.page-content .session-form .google-btn img {
  width: 34px;
  margin-right: 1.1em;
}

.page-content .session-form .kep-login-facebook i {
  margin-right: 1.1em;
  font-size: 1.3em;
}

.page-content > .ch-pad > .session > .required-form {
  max-width: 36em;
  border-top: 1px solid lightgray;
  padding-top: 2em;
  margin: 0em auto 1em auto;
}

.page-content > .ch-pad > .session > .required-form > .form-text {
  text-align: start;
  color: black;
}

.page-content > .ch-pad > .session > .form > .note > a {
  color: #000000;
}

.page-content > .ch-pad > .session > .form > .note {
  margin: 0.5em 0 0 0.2em;
  opacity: 0.75;
  font-size: 0.888888em;
  margin-top: 2em;
}

.MuiTabs-indicator {
  background-color: white !important;
  top: 0;
  height: 4px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 110px !important;
  }
}

.ant-checkbox + span {
  color: white;
}

/* share modal style */

.shareLinkModal .ant-modal-content,
.shareLinkModal .ant-modal-header {
  background: black;
  color: white;
}

.shareLinkModal .ant-modal-title,
.shareLinkModal .ant-modal-close {
  color: white;
}

.greenText {
  color: #38ef7d !important;
}
