.playOver {
    position: absolute !important;
    right: -5px;
    left: -1px;
    bottom: -1px;
    top: -1px;
    background-color: #000000;
    z-index: 9999 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 10px;
    padding: 0.6em 1.3em;
    font-size: 0.9em;
}

.playOver>div {
    display: flex;
    align-items: center;
    margin: 0.4em 0;
}

.playOver>div>img {
    margin-left: 1em;
    filter: none !important;
    width: 28px !important;
    cursor: pointer !important;
}