.DashboardContainer {
    display: flex;
    align-items: flex-start;
    padding: 0 1.5em 0 1.5em;
}

.dashParent {
    position: relative;
    background: #f5f5f5;
}

.topGradient {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 130px;
    transform: rotate(180deg);
    z-index: 9;
}

.bottomGradient {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 160px;
    z-index: 9;
}


/* .dashParent::before {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    content: "";
    z-index: 9;
    height: 140px;
    background-image: linear-gradient( 180deg, rgba(253, 253, 253, 0) 0%, rgba(253, 253, 253, 0.78) 42%, #fdfdfd 100%);
    transform: rotate(180deg);
} */