.formWrapper {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.FormContainer {
    width: 100%;
    max-width: 780px;
    margin-right: auto;
    margin-left: 5em;
    margin-top: 29em;
    padding-bottom: 18em;
}

.FormContainer span {
    font-size: 4em;
    line-height: 1.5;
    color: #ffff;
}

.FormContainer a {
    font-size: 4em;
    line-height: 1.5;
    color: #38ef7d;
    cursor: pointer;
    border-bottom: 1px dashed #b14943;
    text-decoration: none !important;
    display: inline-block;
}

.FormContainer a:hover {
    color: #999 !important;
}

.selectContainer a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0em 0.5em;
    font-size: 3em;
    color: #fff;
}

.selectContainer a span {
    font-size: 0.6em;
}

.selectContainer a.active {
    color: #478982;
}

.searchContainer {
    display: flex;
    border-bottom: 1px dashed #fff;
}

.searchContainer input {
    background-color: transparent;
    font-size: 3em;
    border: none;
    width: 100%;
    outline: none !important;
    padding: 0.3em;
    color: #fff;
}

.searchContainer input::placeholder {
    color: #fff;
}

.searchContainer button {
    font-size: 3em;
    color: #fff;
    background-color: #6aafaa;
    border: none;
}

.exampleText {
    padding: 0.3em 1em;
}

.exampleText span {
    font-size: 1.25em;
    color: rgba(0, 0, 0, 0.2);
    font-style: italic;
}

.exampleText p {
    margin-bottom: 0;
    font-size: 1.25em;
    font-style: italic;
    color: #fff;
    display: inline-block;
}

.addNewInput {
    border: none;
    background-color: transparent;
    font-size: 4em;
    line-height: 1.5;
    color: #38ef7d;
    border-bottom: 1px dashed #38ef7d;
    outline: none !important;
}