.ProjectCard {
  min-width: 155px;
  height: 173px;
  border-radius: 10px;
  background-color: #64b5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.7em;
  margin-right: 1em;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  cursor: pointer;
  color: black;
}

.ProjectCard p {
  margin-bottom: 0;
  line-height: 16px;
  font-family: Quicksand;
  color: #38ef7d;
  font-weight: 700;
}

.ProjectCard h4 {
  margin-bottom: 0;
  font-size: 1.5625em;
  font-family: Quicksand;
  font-weight: 700;
  color: white;
  line-height: 16px;
}

.ProjectCard .smText {
  margin-bottom: 0;
  font-size: 0.625em;
  font-family: Quicksand;
  font-weight: 700;
  color: white;
}

.ProjectCard img,
.ProjectCard svg {
  filter: invert(1);
}
