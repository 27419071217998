.LeftSidebar {
  display: flex;
  flex-direction: column;
  margin-top: 10em;
  width: 200px;
  position: fixed;
  z-index: 9;
  padding-right: 3.5em;
}

.sidebarItem img {
  margin-right: 1em;
  filter: invert(1);
  width: 25px;
}

.sidebarItem {
  font-size: 1.125em;
  font-family: Roboto;
  font-weight: 700;
  color: #999999;
  margin-bottom: 1.5em;
  display: flex;
  align-items: center;
}

.sidebarItem:hover {
  color: white !important;
}

.disabledItem {
  opacity: 0.5;
  cursor: not-allowed;
}
