.tag {
    padding: 2px 5px;
    color: #999;
    border: 1px solid #999;
    border-radius: 7px;
    margin-right: 8px;
    height: 23px;
    font-size: 11px;
    margin-bottom: 10px;
    cursor: pointer;
}