html {
  scroll-behavior: smooth;
}

body {
  font-size: 1em !important;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#root {
  overflow: hidden;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.ant-tabs-tab {
  margin: 0 20px 0 0 !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-family: Roboto;
}

.ant-tabs-content,
.ant-tabs {
  height: 100% !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
  font-weight: 700 !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 0 1em !important;
}

.Projects > .cards:nth-child(1) {
  background-color: #80c783;
}

.Projects > .cards:nth-child(6) {
  background-color: #cfd8dd;
}

.Projects > .cards:nth-child(2) {
  background-color: #64b5f5;
}

.Projects > .cards:nth-child(3) {
  background-color: #fdb64d;
}

.Projects > .cards:nth-child(4) {
  background-color: #a7feeb;
}

.Projects > .cards:nth-child(5) {
  background-color: #feab91;
}

.Campaigns > .cards:nth-child(1) {
  background-color: #fdb64d;
}

.Campaigns > .cards:nth-child(2) {
  background-color: #a7feeb;
}

.Campaigns > .cards:nth-child(3) {
  background-color: #80c783;
}

.Campaigns > .cards:nth-child(4) {
  background-color: #64b5f5;
}

.Campaigns > .cards:nth-child(5) {
  background-color: #feab91;
}

.ant-input::placeholder {
  color: black !important;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: absolute !important;
  z-index: 999999;
  width: 345px;
  overflow: auto;
}

.ant-btn:hover,
.ant-btn:focus {
  color: black !important;
}

/* .topSection {
    display: none;
}

.ant-tabs-tabpane-active .topSection {
    display: block;
} */

.ant-tabs-tab:hover {
  color: black;
}

a:hover {
  color: black !important;
}

.ant-popover {
  z-index: 99999999 !important;
}

.ant-popover-inner {
  box-shadow: none !important;
  background-color: transparent !important;
}

.ant-modal-footer {
  display: none !important;
}

.sound-wave {
  width: 100%;
}

.ant-modal-header {
  display: none !important;
}

.ant-modal-close {
  display: none !important;
}

.ant-modal-body {
  padding: 0 !important;
  background-color: black !important;
}

.ant-modal-wrap {
  z-index: 99999999999 !important;
}

.closeBtnPlayer {
  position: fixed;
  right: 1.3em;
  bottom: 2.5em;
  padding: 0.3em 1em;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  color: #38ef7d;
  border-color: #38ef7d;
  z-index: 99999;
}

.infinite-scroll-component__outerdiv {
  padding-bottom: 8em;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

#gjs {
  /* border: 3px solid #444; */
  width: 100% !important;
  background: #141414;
}

/* Reset some default styling */

.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
}

.gjs-toolbar {
  display: none;
}

.gjs-mdl-container {
  display: none;
}

.editor-wrapper {
  display: flex;
  padding: 15px;
}

.gjs-editor {
  height: 100%;
}

.gjs-editor div {
  width: 100%;
  height: 100%;
}

.gjs-frame-wrapper > div {
  display: none;
}

.gjs-frame-wrapper > iframe {
  width: 100%;
  height: 100%;
}

.gjs-frame-wrapper > iframe body {
  background-color: transparent;
}

.fa-desktop,
.fa-mobile,
.fa-tablet {
  color: #38ef7d !important;
}

.sp-hidden,
.gjs-pn-panels > div {
  display: none;
}

.gjs-pn-devices-c {
  display: block !important;
  width: auto;
  height: auto;
  background: transparent !important;
  margin-top: 10px;
}

.gjs-pn-buttons > span {
  display: inline-block;
  margin-right: 10px;
}

.gjs-frame-wrapper {
  margin: auto;
}

.gjs-frame {
  border-width: 0;
}

.gjs-frame body {
  zoom: 0.75;
}

.ant-input::placeholder {
  color: white !important;
}

.MuiSlider-rail {
  width: 100%;
  height: 5px !important;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 1px;
  background-color: white !important;
}

.MuiSlider-thumb {
  color: rgb(77, 78, 78) !important;
}

.MuiSlider-root {
  padding: 0 !important;
  /* padding-bottom: 7px !important; */
  top: -16px;
}

.MuiSlider-rail:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.MuiSlider-thumb {
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiSlider-root:hover .MuiSlider-thumb {
  opacity: 1;
}

.MuiSlider-track {
  height: 5px !important;
}

.onboarding {
  background: white;
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  overflow: auto;
}

.onboarding > .page-content {
  padding-left: 0.5em;
  padding-top: 1em;
}

.node.active circle {
  fill: #145389 !important;
}

.node foreignObject {
  display: none;
}

.node.active .fas {
  color: white;
  font-size: 1.5em;
}

.node.active foreignObject {
  display: block;
  transform: rotate(95deg) translate(-10px, 5px);
}

.onFooter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 95px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.47) 0%,
    rgba(255, 255, 255, 0) 4%,
    rgba(255, 255, 255, 0.2) 13%,
    #ffffff 100%
  );
}

/* new onboarding design */
.new-body-wrapper {
  background-color: #242326;
  color: white;
  height: 100vh;
}

.new-body-wrapper .page-content,
.new-body-wrapper .container,
.new-body-wrapper .session {
  height: 100vh;
}

.new-body-wrapper .session {
  height: 80vh;
  margin-top: 9em;
}

.new-body-wrapper .page-content {
  padding-top: 5%;
}

.new-body-wrapper .session h1 {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 4em;
}

.new-body-wrapper .session p {
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
}

.newFormContainer .formField {
  text-align: left;
}

.newFormContainer .formField label {
  color: #acb1c0;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.87px;
  text-align: left;
  margin-bottom: 7px;
}

.newFormContainer .formField input {
  width: 100%;
}

/* new onboarding design end */
.iframeContainer {
  display: flex;
  justify-content: center;
}
