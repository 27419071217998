.container {
    width: 100%;
    height: 400px;
    /* border: 1px dashed black; */
    border-radius: 15px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='65' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 11px;
}

.container>div {
    text-align: center;
}

.container p {
    font-size: 0.9em;
    margin-top: 10px;
    width: 150px;
}

.dropOverlay {
    opacity: 0;
    transition: all 0.25s ease-in;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.73);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropZoneContainer:hover .dropOverlay {
    opacity: 1;
}

.dropZoneContainer {
    cursor: pointer;
}