.Details {
    padding: 0 1em;
    height: 82%;
    overflow: auto;
}

.Details h3 {
    font-size: 2.0875em;
    font-weight: 700;
    color: #ffffff;
    width: 100%;
    max-width: 283px;
    margin: auto;
}

.Details .member {
    margin-top: 5em;
}

.Details .member .head {
    display: flex;
    align-items: center;
}

.Details .member .head span {
    color: #000000;
    font-family: Roboto;
    font-weight: 700;
    font-size: 0.875em;
    margin-right: 1.5em;
}

.Details .memberDetail {
    display: flex;
    align-items: center;
}

.Details .memberDetail img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.5em;
}

.Details .memberDetail .memberName p:nth-child(1) {
    color: #131026;
    font-family: Poppins;
    font-size: 0.875em;
    font-weight: 500;
    margin-bottom: 0;
}

.Details .memberDetail .memberName p:nth-child(2) {
    color: #000000;
    font-size: 0.6875em;
    margin-bottom: 0;
    font-weight: 500;
}

.Details .actionBtn img {
    cursor: pointer;
    margin: 0 0.5em;
    filter: invert(1);
}

::-webkit-scrollbar {
    display: none;
}


/* recent items tab start */

.RecentItem {
    padding: 0.5em 1em 0em 1em;
    height: 100%;
    /* overflow: auto; */
}

.RecentItem h4 {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: 1em;
}

.RecentItemTags {
    /* padding: 1em 1em 0 1em; */
}

.RecentItemTags a {
    font-size: 12px;
    font-weight: 400;
    font-family: Titillium Web;
    display: inline-block;
    margin-right: 0.5em;
    border: 1px solid white;
    border-radius: 20px;
    color: white;
    padding: 0.1em 1em;
}

.RecentItemTags a:hover {
    background-color: rgba(255, 255, 255, 0.63);
    color: black;
}

.RecentItemTags a.active {
    background-color: rgba(255, 255, 255, 0.63);
    color: black;
}

.RecentItemCards {
    padding: 6em 1em;
    height: 100%;
    overflow: auto;
    padding-top: 3em;
    /* padding-bottom: 6em; */
    /* margin-top: 1em; */
}

.RecentItemCard {
    display: flex;
    align-items: flex-start;
    padding: 1em 0 0 0;
    margin-bottom: 1em;
}

.RecentItemCard .backgroundImage {
    min-width: 64px;
    min-height: 64px;
    background-color: #000000;
    opacity: 0.34;
    margin-right: 1em;
    transition: all 500ms;
    cursor: pointer;
    background-size: cover;
    border-radius: 5px;
}

.RecentItemCard .backgroundImage:hover {
    opacity: 1;
}

.RecentItemCard .RecentItemCardContent h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 18px;
}

.RecentItemCardContent p {
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 0.5em;
}

.RecentItemCardContent span {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.RecentItemCardContent .actionBtn img {
    margin: 0 0.3em;
    cursor: pointer;
    filter: invert(1);
}


/* recent items tab end */


/* add items tab start */

.AddItem {
    overflow: auto;
    height: 100%;
}

.AddItem .actionBtn,
.popupCard .actionBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1em;
    margin-bottom: 3em;
    padding: 0 1em;
    flex-wrap: wrap;
}

.AddItem .actionBtn>.active,
.popupCard .actionBtn>.active {
    border: 2px solid #38ef7d;
    border-radius: 10px;
}

.AddItem .actionBtn button,
.popupCard .actionBtn button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 78px;
    height: 75px;
    padding: 0.5em;
    background-color: transparent;
    border: none;
    width: 76px;
    margin: 0 5px;
    margin-bottom: 10px;
}

.AddItem .actionBtn button img {
    filter: invert(1);
}

.popupCard .actionBtn button img {
    margin-top: 0;
    /* filter: invert(1) !important; */
}

.AddItem .actionBtn button span,
.popupCard .actionBtn button span {
    font-size: 10px;
    font-family: Poppins;
    margin: 0 -5px;
    font-weight: 400;
    margin-top: 7px;
    color: white;
    line-height: 11px;
}

.searchInput {
    border: 1px solid white !important;
    border-radius: 10px;
    font-size: 0.8em;
    width: 100%;
    padding: 6px 9px;
    margin-bottom: 3.5em;
    outline: none;
    color: white;
    background: transparent;
}

.searchInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5;
}

.searchInput+img {
    filter: invert(1);
}

.popupCard label {
    color: #38ef7d;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0;
}

.popupCard label+input {
    background: transparent;
    border: 1px solid #999;
    border-radius: 5px;
    width: 100%;
}


/* add items tab end */

.topSection {
    z-index: 999999;
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 1em;
    top: 1em;
    padding-left: 2em;
}

.inputContainer {
    width: 100%;
    /* padding: 0 18px; */
    margin-bottom: 2em;
}

.inputContainer input {
    border: none;
    outline: none;
    border-bottom: 1px solid #999;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    color: black;
}

.inputContainer textarea {
    background-color: transparent;
}

.inputContainer label {
    font-weight: bold;
    font-size: 0.9em;
}

.podcastBox {
    min-width: 148px;
    height: 173px;
    border: 1px solid #999;
    border-radius: 15px;
    padding: 0.8em;
    line-height: 17px;
    font-weight: 600;
    margin-right: 1em;
    font-size: 0.9em;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    color: #999;
}

.boxActive {
    color: white;
    border: 1px solid white;
}

.boxActive::after {
    position: absolute;
    left: 10%;
    width: 80%;
    bottom: -25px;
    border-radius: 15px;
    height: 5px;
    background: #38ef7d;
    content: " ";
}

.userImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: black !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.userDetail {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;
}

.userDetail img {
    cursor: pointer;
    filter: invert(1);
}

.userDetail h6 {
    color: #38ef7d !important;
    font-size: 10px !important;
}

.userItem p {
    margin-bottom: 0;
    font-size: 14px;
}

.userItem p:last-child {
    font-size: 11px;
    margin-top: -4px;
}

.smallUserItem p {
    font-size: 10px !important;
}

.roundedBox {
    cursor: pointer;
    width: 170px;
    padding: 6px 5px;
    border-radius: 10px;
    font-size: 0.8em;
    border: 1px solid transparent;
    background: transparent !important;
    border: 1px solid #999 !important;
}

.voiceRecord {
    position: relative;
    z-index: 9999999999;
}

.voiceRecord img {
    cursor: pointer;
    margin-top: 2em;
    filter: invert(1);
}


/* .commentartContainer img {
    filter: none;
} */

.reverbContainer {
    height: 69px;
    border-radius: 5px;
    background-color: #1d1d1d;
    margin-bottom: 10px;
}

.reverbContainer p {
    color: #464646;
    font-size: 48px;
    font-weight: 700;
    /* line-height: 1em; */
    text-align: center;
    width: 28px;
    margin: 0;
}

.reverbContainer span {
    font-size: 10px;
}

.reverbContainer .switch {
    border: 1px solid white;
}

.reverbContainer .switch>div:first-child {
    top: 1px !important;
}

.reverbContainer .switch>span:nth-child(2) {
    line-height: 1px !important;
}

.reverbContainer .activeSwitch {
    border: 1px solid #38ef7d;
    background: transparent;
}

.reverbContainer .activeSwitch>div:first-child::before {
    background: transparent;
    border: 1px solid #38ef7d;
}

.reverbContainer .activeSwitch>span:nth-child(2) {
    color: #38ef7d;
}

.greenColor {
    color: #38ef7d;
}

.activeBox {
    border-color: #38ef7d !important;
    color: #38ef7d !important;
}


/*  */

.storyTemplateList {
    overflow-y: auto;
    padding-bottom: 5em;
    padding-top: 3em;
    /* height: 500px; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px;
    padding-bottom: 5em;
    padding-top: 300px;
}

.kenBurns {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    height: 186px;
    margin-bottom: 20px;
    cursor: pointer;
}

.kenBurns img {
    height: 156px;
    width: 100%;
    animation-iteration-count: infinite;
    animation: move 30s ease;
    animation-direction: alternate;
    /* Add infinite to loop. */
    position: absolute;
}

@keyframes move {
    0% {
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        -o-transform-origin: bottom left;
        transform-origin: bottom left;
        transform: scale(1);
        -ms-transform: scale(1);
        /* IE 9 */
        -webkit-transform: scale(1);
        /* Safari and Chrome */
        -o-transform: scale(1);
        /* Opera */
        -moz-transform: scale(1);
        /* Firefox */
    }
    100% {
        transform: scale(1.2);
        -ms-transform: scale(1.2);
        /* IE 9 */
        -webkit-transform: scale(1.2);
        /* Safari and Chrome */
        -o-transform: scale(1.2);
        /* Opera */
        -moz-transform: scale(1.2);
        /* Firefox */
    }
}