.RightSidebar {
    border-radius: 10px;
    width: 344px;
    height: 75%;
    overflow: hidden;
    position: fixed;
    right: 25px;
    top: 123px;
    box-shadow: 0px 0px 3px #000000;
    z-index: 99999;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fbfbfb;
    transition: all 0.25s ease-in;
}

.footer {
    height: 86px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 0 0;
    z-index: 9999999;
}

.topGradient {
    /* width: 343px; */
    /* height: 125px; */
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
    transform: rotate(180deg);
}

.tabBody {
    position: relative;
    z-index: 999;
    height: 100%;
}

.tabContainer {
    position: absolute;
    right: 380px;
    z-index: 999999999;
    top: 9em;
    transition: all 0.25s ease-in;
}

.tabContainer div {
    margin-bottom: 10px;
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    text-transform: uppercase;
    border: 1px solid transparent;
    padding: 0 10px;
    border-radius: 8px;
    cursor: pointer;
}

.tabContainer div.active {
    font-weight: 700;
    border: 1px solid #38ef7d;
    color: #38ef7d;
}

.expandIcon {
    display: none;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 99999;
    left: 10px;
    top: 10px;
    transition: all 0.25s ease-in;
    transform: rotate(180deg);
}

.expandIcon.expandedIcon {
    transform: rotate(0deg);
}

@media (max-width: 1500px) {
    .expandIcon {
        display: block;
    }
    .RightSidebar {
        transform: translateX(310px);
    }
    .tabContainer {
        transform: translateX(310px);
    }
    .RightSidebarExpanded {
        transform: translateX(0);
    }
    .tabContainerExpanded {
        transform: translateX(0px);
    }
}