.Header {
    background-color: transparent;
    padding: 0.5em 1em;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 99;
}

.Header nav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.logo {
    width: 84px;
}

.HeaderNavbar {
    display: flex;
    align-items: center;
}

.userProfile {
    display: flex;
    align-items: center;
}

.userProfileDropdown {
    display: flex;
    align-items: flex-start;
    margin-right: 1.8em;
}

.userName {
    margin-left: 10px;
}

.userName p {
    margin-bottom: 0;
    font-family: Montserrat;
    font-size: 0.9375em;
    color: #131026;
}

.userName p:nth-child(2) {
    font-size: 0.75em;
    color: #6c708c;
}

.NavbarLinks {
    margin-right: 10em;
}

.NavbarLinks a {
    color: #fff;
    font-size: 1.125em;
    font-weight: 700;
    padding: 0 1.2em;
}

.NavbarLinks a:hover {
    color: #fff;
    opacity: 0.5;
}