.addSourcesBtn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Titillium Web", sans-serif;
    font-size: 13px;
    color: #fff;
    outline: none !important;
    border: 5px solid #fff;
    border-radius: 15px;
    padding: 17px;
    position: relative;
    width: 70px;
}

.addSourcesBtn input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.addSourcesBtn img {
    width: 29px;
    height: 29px;
    filter: invert(1);
}

.addSourcesBtn+span {
    font-size: 12px;
    display: inline-block;
    text-align: center;
    width: 100%;
    color: white;
}

.createContainer {
    width: 62em;
    padding: 3em;
    padding-left: 6em;
    display: flex;
    align-items: center;
    margin-top: -3em;
}

.inputContainer {
    margin-left: 7em;
    width: 560px;
}

.inputContainer input {
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    width: 100%;
    background-color: transparent;
}

.inputContainer select {
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    width: 100%;
    background-color: transparent;
}

.inputContainer textarea {
    background-color: transparent;
}

.inputContainer label {
    font-weight: bold;
    font-size: 0.9em;
}

.colorBtn {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin-right: 15px;
    border: none;
}

.inputAppend {
    position: relative;
}

.saveBtn {
    font-size: 0.7em;
    border: 2px solid white;
    border-radius: 7px;
    text-align: center;
    width: 60px;
    margin-top: 20px;
    float: right;
    z-index: 999;
    position: relative;
    background: transparent;
}

.searchInput {
    border: 1px solid white !important;
    border-radius: 10px;
    font-size: 0.8em;
    width: 100%;
    padding: 6px 9px;
    margin-bottom: 3.5em;
    outline: none;
    color: white;
}

.searchInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5;
}

.campaignStatBtn {
    width: 170px;
    margin-left: 1em;
    margin-top: 7.1em;
}

.campaignStatImb {
    width: 65px !important;
    height: 65px !important;
    margin-bottom: -15px;
}

.activeTabBtn {
    border: 1px solid #38ef7d;
    margin-right: 8px;
    color: #38ef7d !important;
}

.activeTabBtn img {
    filter: invert(1);
}

.tabBtn {
    border: 1px solid white;
    margin-right: 8px;
}

.pubBtn {
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    padding: 6px 11px;
    border-radius: 10px;
    font-size: 0.95em !important;
    border: 1px solid transparent;
    color: white !important;
}

.pubBtn:hover {
    background-color: #1ed760;
    border-color: transparent !important;
}

@media (max-width: 1300px) {
    .maninContainer {
        overflow-x: hidden;
        padding-top: 3.5em;
        margin-top: -3.5em;
    }
}