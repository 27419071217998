.container {
    width: 325px;
    height: 120px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background-color: #000000;
    position: fixed;
    bottom: 50px;
    right: 25%;
    z-index: 9999999;
}

.title {
    font-size: 16px;
    color: white;
    font-weight: bold;
    margin-left: 2em;
    margin-top: -0.1em;
    margin-bottom: 0;
}

.sliderBackground {
    width: 140px;
    height: 8px;
    background-color: #5d5d5d;
    margin-top: 1em;
    border-radius: 5px;
    margin-left: 3em;
}

.slider {
    height: 8px;
    border-radius: 5px;
    background-color: #38ef7d;
    display: block;
}