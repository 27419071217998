.navbar {
  line-height: 1;
  padding: 1.25em;
  z-index: 9999;
}

.navbar > .brand {
  font-size: 30px;
  font-weight: 800;
}

.navbar > .links {
  cursor: pointer;
}

.navbar > .links > a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 800;
  color: black;
  margin-right: 1.5em;
  cursor: pointer;
}

.navbar > .links > a.btn-primary {
  color: white;
}

.navbar > .sign-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  min-height: 2.4em;
  font-size: 1rem;
  font-weight: 400;
}

.navbar > .sign-brand > span {
  font-size: 30px;
  font-weight: 800;
  margin-right: 1em;
}

.navbar > .sign-brand > .navigation {
  margin-left: 0.5em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar > .sign-brand > .navigation .search-field {
  margin-left: 0.4em;
  margin-top: -0.3em;
  height: 1.9em;
  color: #000000;
}

.navbar > .sign-brand > .navigation .input {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  max-width: 12em;
}

.navbar > .sign-brand > .navigation .svg-icon {
  color: inherit;
  position: absolute;
  margin-top: 0.04em;
  margin-left: 0.5em;
}

.navbar > .sign-brand > .navigation svg {
  max-height: 1em;
  max-width: 1.25em;
}

.navbar > .sign-brand > .navigation .brand-input {
  margin: 0 auto;
  padding: 0.29em 0.75em 0.35em 1.95em;
  height: 2.25em;
  background: none;
  background-color: inherit;
  font-size: 1em;
  font-weight: 800;
  border-color: rgba(255, 255, 255, 0);
  border-width: 0.2em;
  border-radius: 0.3em;
}

.navbar > .sign-brand > .navigation .brand-input:hover {
  border-color: #000000;
  background: #fff;
}

.navbar > .sign-brand > .navigation .search-label {
  display: none;
}

.navbar .user-img {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: 10px;
}

.MuiTab-root {
  text-transform: unset !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 1.3em !important;
}
