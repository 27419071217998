.collpaseIcon {
    width: 26px;
    margin-right: 1em;
}

.userName {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1em;
}

.linksHolder {
    display: flex;
    justify-content: space-between;
    margin-top: -0.4em;
}

.logoHolder {
    width: 177px;
    display: flex;
    justify-content: space-around;
    margin-right: 7em;
}

.logoHolder img {
    width: 37px;
    height: 37px;
    cursor: pointer;
}

.spotLogo {
    filter: grayscale(100);
}

.searchInput input {
    width: 200px;
    border-radius: 10px;
    background-color: rgb(33 33 33);
    border-color: #2b2b2b;
}