.LatestFeed {
    margin-top: 3em;
    height: 610px;
    width: 100%;
}

.LatestFeed h5 {
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    font-family: Roboto;
    margin-bottom: 1em;
}

.FeedTags {
    display: flex;
    align-items: center;
}

.FeedTags a {
    transition: 0.05s ease-in;
    width: 84px;
    height: 22px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: Titillium Web;
    font-weight: 400;
    margin-right: 1.5em;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
}

.FeedTags a:hover {
    color: white !important;
}

.FeedTags a.active {
    color: #38ef7d;
    border-color: #38ef7d;
}

.dropContainer {
    width: 100%;
    background-color: transparent;
    border-radius: 15px;
    min-height: 540px;
    align-items: flex-start;
    justify-content: flex-start;
}


/* .editorContainer {
    min-height: 500px;
    margin-left: 2.5em;
    width: 100%;
    background-color: #eef5fa;
    border-radius: 100px;
    margin: 0 auto;
    padding: 70px 60px;
} */

.editorJs {
    width: 900px;
    background: #141414;
    border-radius: 8px;
    /* -webkit-box-shadow: 0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%);
    box-shadow: 0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%); */
    padding: 70px 50px;
    font-size: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.statusBar {
    width: 900px;
    min-height: auto;
    background: #141414;
    height: 100px;
    display: flex;
}

.voiceAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.statusActionBtn {
    cursor: pointer;
    text-transform: uppercase;
    padding: 3px 11px;
    border-radius: 5px;
    font-size: 0.6em !important;
    color: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.greenButton {
    border-color: #38ef7d;
    color: #38ef7d !important;
}

.redButton {
    border-color: red;
    color: red !important;
}

.memberCard {
    width: 275px;
    height: 100%;
    min-height: 140px;
    border-radius: 5px;
    background-color: #1d1d1d;
    margin-bottom: 15px;
    margin-right: 15px;
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px 10px;
}

.memberCard .name {
    width: 111px;
    height: 25px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
}

.memberCard .role {
    height: 15px;
    color: #38ef7d;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    font-family: Poppins;
    font-weight: 400;
}

.memberCard .image {
    width: 53px;
    height: 54px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.memberCard .actionImage {
    margin-bottom: 15px;
    width: 21px;
    height: 20px;
    cursor: pointer;
}

.storyOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    cursor: pointer;
}