.button {
  padding: 0.4375em 1.5em 0.5em;
  font-size: 1em;
  cursor: pointer;
  border: 3px solid black;
  color: white;
  font-weight: 800;
  background-color: #000000;
  border-radius: 2.5em;
  outline: 0;
}

.button i {
  display: inline-block;
  margin-right: 1em;
}

.button:hover {
  background-color: #000000;
  border: 3px solid black;
}

.button:focus {
  border: 3px solid black;
  box-shadow: none;
}

.page-content .black-btns {
  margin-bottom: 1em;
  width: 380px;
  padding: 0.656em 1.5em 0.6em;
  border-radius: 2.5em;
  font-size: 1.2em;
  text-align: center;
}

.black-outline-input {
  display: inline-block;
  width: 100%;
  padding: 0.5em 0.75em;
  font-size: 15px;
  /* font-family: "Avenir Next W01", "Avenir Next", "Avenir", helvetica, arial,
        sans-serif; */
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #333;
  border: none;
  height: 56px;
  border-radius: 15px;
  background-color: #edf1f7;
  appearance: none;
  padding: 0.5em 0.75em;
  outline: 0;
}

.black-outline-input::-webkit-input-placeholder {
  color: #b9b3b3;
  font-weight: normal;
  font-size: 14px;
}

.black-outline-input:active {
  outline: 0;
  color: #000000;
  border-color: #000000;
}

.black-outline-input:focus {
  outline: 0;
  color: #000000;
  border-color: #000000;
}

.page-content .black-square-btn {
  width: 100%;
  padding: 0.656em 1.5em 0.6em;
  background: #000000;
  font-size: 1.5em;
  font-weight: 800;
}

button {
  outline: none !important;
}
