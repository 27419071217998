.backgroundOverlay {
    background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0.58) 48%, rgba(0, 0, 0, 0.69) 54%, rgba(0, 0, 0, 0.84) 100%);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    z-index: 99;
}

.backgroundImage {
    background-size: cover;
    background-position: center;
}

.itemType {
    text-transform: uppercase;
    position: fixed;
    color: black !important;
    left: none;
    top: -40px;
    font-size: 653px;
    font-weight: 700;
    pointer-events: none;
}

.actionBtn {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
}

.queue {
    background-color: transparent !important;
    height: 100%;
    position: fixed !important;
    right: 30px;
    top: 85px;
    overflow: auto;
    z-index: 999;
}

.sliderItem {
    display: flex !important;
    justify-content: center;
    width: 80% !important;
    margin-top: 4em;
}

.gridContainer {
    position: absolute;
    padding-top: 35%;
    left: 50px;
    min-height: 170px;
    /* height: 200px; */
    width: 80% !important;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    justify-content: flex-start;
    /* align-items: center; */
    bottom: 0;
    overflow: auto;
    top: 0;
}

.gridContainer>div {
    /* height: 1000px; */
}

.gridContainer h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 50px;
    text-align: left;
    min-width: 300px;
    max-width: 350px;
    height: 3000px;
}

.bgOverlay {
    background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0.94) 0%, rgba(0, 0, 0, 0.72) 48%, rgba(0, 0, 0, 0.75) 54%, rgba(0, 0, 0, 0.94) 100%);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}

.mainPage {
    position: relative;
    z-index: 999;
    margin-top: 0;
    padding-top: 20px;
}


/* new queue at bottom */

.bottomQueue {
    position: fixed;
    bottom: 2px;
    right: 0;
    left: 0;
    background: transparent;
    z-index: 999999999999;
}

.bottomQueue>div {
    height: 110px;
    display: flex;
    align-items: flex-end;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
    margin-bottom: 19px;
    margin-left: 24px;
}

.bottomQueue span {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.4em;
}

.bottomQueue span:first-child {
    margin-left: 29px;
}

@media (max-width: 768px) {
    .bottomQueue>div {
        height: auto;
    }
    .bottomQueue span {
        font-size: 1em !important;
        margin-left: 11px;
    }
}