.progressContainer {
    width: 100%;
    /* background-color: rgb(77, 78, 78); */
    /* opacity: 0.5; */
    transition: all 0.25s;
    position: fixed;
    background: transparent;
    z-index: 99999;
    bottom: 8px;
    left: 30px;
    width: 96%;
    z-index: 9999999999;
    height: 6px;
    align-items: center;
}


/* .progressContainer:hover {
    opacity: 1;
} */

.progressContainer>span span:first-child {
    margin-left: 0 !important;
}

.progressContainer>span {
    margin-left: 0 !important;
}